import {useEffect, useRef} from "react";
import { createChart, ColorType } from 'lightweight-charts';

export const Chart = props => {
    const {
        data,
        colors: {
            backgroundColor = 'rgba(0,0,0,0)',
            lineColor = '#5eff31',
            textColor = '#999999',
            areaTopColor = '#5eff31',
            areaBottomColor = 'rgba(0, 0, 0, 0)',
        } = {},
        indexChartId
    } = props;

    const chartContainerRef = useRef();

    useEffect(
        () => {

            const chartContainer = document.getElementById('chart-container');

            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef.current.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    fontFamily: 'Space Mono, monospace, Roboto, Ubuntu, Arial, sans-serif',
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    resizeMode: 'contain',
                    horizontalLineColor: '#5eff31',
                    verticalLineColor: 'rgba(0, 0, 0, 0)',
                },
                height: 420,
                autoSize: true,
                grid: {
                    vertLines: { color: '#333' },
                    horzLines: { color: '#333' },
                },
            });



            // Setting the border color for the vertical axis
            chart.priceScale('right').applyOptions({
                borderColor: 'rgba(0,0,0,0)',
            });

// Setting the border color for the horizontal axis
            chart.timeScale('bottom').applyOptions({
                borderColor: 'rgba(0,0,0,0)',
            });



            /*new ResizeObserver(entries => {
                if (entries.length === 0 || entries[0].target !== chartContainer) { return; }
                const newRect = entries[0].contentRect;
                chart.applyOptions({ height: newRect.height, width: newRect.width });
            }).observe(chartContainer);*/

            chart.timeScale().fitContent();
            chart.applyOptions({
                timeScale: {
                    visible: true,
                    timeVisible: true,
                    fixRightEdge: true,
                },
                // handleScroll: false,
                // handleScale: false,
                allowChartUpdate: true,
                immutable: true,
                layout: {
                    attributionLogo: false,
                },
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.3, // leave some space for the legend
                        bottom: 0.25,
                    },
                },
                crosshair: {
                    // hide the horizontal crosshair line
                    horzLine: {
                        visible: true,
                        labelVisible: true,
                        width: 1,
                        color: '#333',
                        labelBackgroundColor: '#333',
                    },
                    // hide the vertical crosshair label
                    vertLine: {
                        visible: true,
                        labelVisible: true,
                        color: '#333',
                        labelBackgroundColor: '#333',
                    },
                },
                // hide the grid lines
                grid: {
                    vertLines: {
                        visible: false,
                    },
                    horzLines: {
                        visible: false,
                    },
                },
            });

            const newSeries = chart.addAreaSeries({ lineColor, topColor: areaTopColor, bottomColor: areaBottomColor });
            newSeries.applyOptions({
                priceFormat: {
                    type: 'price',
                    precision: 6,
                    minMove: 0.000001,
                },
            });
            newSeries.setData(data);

            window.addEventListener('resize', handleResize);





            /*const series = chart.addAreaSeries({
                topColor: 'rgba( 38, 166, 154, 0.28)',
                bottomColor: 'rgba( 38, 166, 154, 0.05)',
                lineColor: 'rgba( 38, 166, 154, 1)',
                lineWidth: 2,
                // crossHairMarkerVisible: false,
            });*/


            const toolTipWidth = 80;
            const toolTipHeight = 80;
            const toolTipMargin = 15;

// Create and style the tooltip html element


            const toolTip = document.createElement('div');
            toolTip.classList.add('tooltip');
            toolTip.classList.add(indexChartId);
            /*toolTip.style = `width: 96px; height: 80px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
            toolTip.style.background = 'white';
            toolTip.style.color = 'black';
            toolTip.style.borderColor = 'rgba( 38, 166, 154, 1)';*/
            chartContainerRef.current.appendChild(toolTip);



// update tooltip
            chart.subscribeCrosshairMove(param => {

                // const price = data[data.length - 1].value;

                if (
                    param.point === undefined ||
                    !param.time ||
                    param.point.x < 0 ||
                    param.point.x > chartContainer.clientWidth ||
                    param.point.y < 0 ||
                    param.point.y > chartContainer.clientHeight
                ) {
                    toolTip.style.display = 'none';
                } else {
                    // time will be in the same format that we supplied to setData.
                    // thus it will be YYYY-MM-DD
                    const dateStr = param.time;
                    toolTip.style.display = 'block';
                    // const data = param.seriesData.get(series);
                    const data = param.seriesData.get(newSeries);
                    const price = data.value;
                    // console.log(data)
                    toolTip.innerHTML = `${(price).toLocaleString(undefined, {maximumFractionDigits: 5, minimumFractionDigits: 5})}`;

                    const y = param.point.y;
                    let left = param.point.x + toolTipMargin;
                    if (left > chartContainer.clientWidth - toolTipWidth) {
                        left = param.point.x - toolTipMargin - toolTipWidth;
                    }

                    let top = y + toolTipMargin;
                    if (top > chartContainer.clientHeight - toolTipHeight) {
                        top = y - toolTipHeight - toolTipMargin;
                    }
                    toolTip.style.left = left + 'px';
                    toolTip.style.top = top + 'px';
                }
            });









            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef}
        />
    );
};

export default Chart;