import Home from "./Home";
import Header from "./Header";

function App() {
  return (
    <div className="app">

        <Home />

    </div>
  );
}

export default App;
