import React, {useEffect, useState} from 'react';
import './Indexes.css';
import {Chart} from "./Chart";


const Indexes = ({indexesRef}) => {

    const [debt, setDebt] = useState(35464673929171.42);
    const [caps, setCaps] = useState([0]);

    const [btcCap, setBtcCap] = useState(0);
    const [btcHistory, setBtcHistory] = useState([]);

    const [ethCap, setEthCap] = useState(0);
    const [ethHistory, setEthHistory] = useState([]);

    const [totCap, setTotCap] = useState(0);
    const [totHistory, setTotHistory] = useState([]);

    const fetchIndexes = async () => {

        await fetch('https://apiindex.ru/api/get_caps', {method: 'GET'})
            .then((response) => response.json())
            .then((response) => {

                // console.log(JSON.parse(response));

                const res = JSON.parse(response[0]);

                setBtcCap(+res?.btc[res?.btc?.length - 1]?.value);
                setBtcHistory(res?.btc);

                setEthCap(+res?.eth[res?.eth?.length -1 ]?.value);
                setEthHistory(res?.eth);

                setTotCap(+res?.tot[res?.tot?.length - 1]?.value);
                setTotHistory(res?.tot);

                setDebt(+res[2]);



                // setCaps(res[1].push(+res[2]));
                setCaps([
                    {
                        name: 'BTC M.CAP',
                        value: JSON.parse(response[1])?.btc_cap
                    },
                    {
                        name: 'ETH M.CAP',
                        value: JSON.parse(response[1])?.eth_cap
                    },
                    {
                        name: 'TOTAL M.CAP',
                        value: JSON.parse(response[1])?.tot_cap
                    },
                    {
                        name: 'U.S. DEBT',
                        value: +JSON.parse(response[2])[0]
                    }
                ]);



            })
            .catch((e) => {
                console.log(e);
                // fetchIndexes();
            })

    }

    useEffect(() => {

        fetchIndexes();

    }, []);



    useEffect(() => {

        const interval = setInterval(() => {

            if (btcCap > 0.0001) {

            } else {
                fetchIndexes();
            }

        }, 4000)

        return () => clearInterval(interval);

    }, [btcCap, ethCap, totCap]);


    const [btcChange, setBtcChange] = useState(0);
    const [ethChange, setEthChange] = useState(0);
    const [totChange, setTotChange] = useState(0);

    useEffect(() => {

        const changePoint = ((Date.now() / 1000) - 86400);


        const btcAgoInUsd = btcHistory?.find((change) => change.time > changePoint)?.value;
        const btcNowInUsd = btcHistory[btcHistory.length - 1]?.value;

        setBtcChange((btcNowInUsd - btcAgoInUsd) / btcNowInUsd * 100);


        const ethAgoInUsd = ethHistory?.find((change) => change.time > changePoint)?.value;
        const ethNowInUsd = ethHistory[ethHistory.length - 1]?.value;

        setEthChange((ethNowInUsd - ethAgoInUsd) / btcNowInUsd * 100);


        const totAgoInUsd = totHistory?.find((change) => change.time > changePoint)?.value;
        const totNowInUsd = totHistory[totHistory.length - 1]?.value;

        setTotChange((totNowInUsd - totAgoInUsd) / totNowInUsd * 100);

        /*console.log(btcHistory?.find((change) => change.time > changePoint));
        console.log(btcHistory[btcHistory.length - 1]?.time);*/

    }, [btcCap, ethCap, totCap])


    const indexes = [
        {
            ticker: 'DEBT-BTC',
            value: btcCap,
            formula: 'BTC M.CAP / U.S. DEBT',
            chart: btcHistory,
            change: btcChange?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
        },
        {
            ticker: 'DEBT-BTCETH',
            value: ethCap,
            formula: 'BTC+ETH M.CAP / U.S. DEBT',
            chart: ethHistory,
            change: ethChange?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
        },
        {
            ticker: 'DEBT-TOTAL',
            value: totCap,
            formula: 'TOTAL M.CAP / U.S. DEBT',
            chart: totHistory,
            change: totChange?.toLocaleString(undefined, {maximumFractionDigits: 2, minimumFractionDigits: 2})
        },
    ]


    return (
        <div ref={indexesRef} className={'indexes-wrapper'}>

            <h1 className={'space'}>
                The First Series of
                <br/>
                CeFi to DeFi Indices
            </h1>

            <div className={'indexes-caps-container'}>

                {
                    caps.map((cap, capIndex) =>
                        <div className={'indexes-cap-item'} key={capIndex} style={{display: debt < 100 && capIndex === 3 ? 'none': 'flex'}}>

                            {cap.name}:&nbsp;$
                            {
                                cap.value > 1000000000000 ?
                                    (cap.value / 1000000000000).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) + 'T'
                                    :
                                    (cap.value / 1000000000).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3}) + 'B'
                            }

                        </div>
                    )
                }

            </div>

            <div className={'indexes-container'}>

                {
                    indexes.map((item, i) =>
                        <div className={'index-item'} key={i}>

                            <div className={'index-item-top'}>

                                <div className={'index-item-top-left'}>

                                    <h3 className={'space'}>
                                        {item.ticker}
                                        &nbsp;
                                        <span
                                            className={`space ${item?.change === 0 ? 'white' : item?.change > 0 ? 'green' : 'red'}`}
                                            style={{display: isNaN(item?.change) ? 'none' : 'flex'}}
                                        >
                                            {item?.change === 0 ? '' : item?.change > 0 ? '+' : '-'}{Math.abs(item?.change)}%
                                        </span>
                                    </h3>

                                    <p className={'space'}>
                                        {item.formula}
                                    </p>

                                </div>

                                <div className={'index-item-top-right'}>

                                    {/*<h3 className={`space ${item?.change > 0 ? 'green' : 'red'}`}>
                                            {item?.change > 0 ? '+' : ''}{item?.change}%
                                    </h3>*/}

                                    <h2 className={'space'}>
                                        ${(item.value).toLocaleString(undefined, {maximumFractionDigits: 5})}
                                    </h2>
                                </div>

                            </div>

                            <div id={'chart-container'}>
                                <Chart data={item.chart} indexChartId={i + 1}></Chart>
                            </div>

                        </div>
                    )
                }

            </div>

        </div>
    );
};

export default Indexes;