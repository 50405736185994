import React from 'react';
import './Header.css';
import logo from './assets/logo.svg';



const nav = [
    'HOME',
    'INDICES',
    'ABOUT'
]


const Header = ({indexesScroll, aboutScroll, topScroll}) => {
    return (
        <div className={'header-wrapper'}>

            <div className={'header-left'}>

                <img src={logo}/>

                <span className={'space'}>
                    PUMP ORGANIZATION
                </span>

            </div>

            <div
                className={'header-right'}
            >

                {
                    nav.map((item, i) =>
                        <p
                            onClick={i === 1 ? indexesScroll : i === 2 ? aboutScroll : topScroll}
                            className={'space'}
                        >
                            {item}
                        </p>
                    )
                }

            </div>

        </div>
    );
};

export default Header;