import React, {useRef} from 'react';
import './Home.css';
import Countdown from "react-countdown";
import back from "./assets/back.svg";
import Header from "./Header";
import Indexes from "./Indexes";
import About from "./About";

const Home = () => {

    const topRef = useRef(null);
    const indexesRef = useRef(null);
    const aboutRef = useRef(null);

    return (
        <div ref={topRef} className={'main-wrapper'}>

            <Header
                topScroll={() => topRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                indexesScroll={() => indexesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                aboutScroll={() => aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            />

            <div className={'timer-wrapper space'}>

                <img src={back} className={'home-back-img'}/>

                <h1>
                    Something monumental
                    <br/>
                    is about to happen
                </h1>

                <div className={'timer space'}>
                    <Countdown date={1730790560000}/>
                </div>

                <p>
                    A framework of innovative financial mechanisms built on truly decentralized tools
                </p>


                <button
                    className={'big-button'}
                    onClick={() => {
                        indexesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                >
                    EXPLORE INDICES
                </button>


            </div>

            <Indexes indexesRef={indexesRef} />


            <About aboutRef={aboutRef} />


            <p className={'space bottom-copyright'}>
                Contact us: pr@trumpump.app
                <br/>
                © 2024 TrumPump All rights reserved.
            </p>

        </div>
    );
};

export default Home;