import React from 'react';
import './About.css';

const About = ({aboutRef}) => {
    return (
        <div ref={aboutRef} className={'about-wrapper'}>


            <h1 className={'space'}>
                Introduction to
                <br/>
                PUMP Organization
            </h1>

            <p className={'space'}>
                Today, it's clear that the U.S. dollar, as the global reserve currency, is fundamentally based on trust in its issuer, and ultimately, trust in the U.S. government. The abandonment of the Bretton Woods system—otherwise known as the gold standard—forever undermined the integrity of the global economic system. We are still witnessing the consequences of these decisions: for a long time, the U.S. dollar has replaced what should have been a fair global monetary system—a system of equal economic rights for all nations and individual citizens alike.
            </p>

            <p className={'space'}>
                The International Monetary Fund, under the leadership of Kristalina Georgieva, is now presenting what is being called the “New Bretton Woods Moment.” This shift is happening right before our eyes, yet the transparency of the process is highly questionable. What’s clear is that we are approaching the end of the era of the old financial system, with the U.S. dollar at its center. But what comes next is still unclear, with no concrete guidelines being offered. Only a small fraction of the global population is beginning to realize what’s happening and is taking tentative steps to safeguard their assets and seek out new, more reliable financial instruments to protect the true value accumulated by civilization over the past centuries.
            </p>


        </div>
    );
};

export default About;